import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask'

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { NetsheetCalculatorComponent } from './netsheet-calculator.component';
import { CustomerInformationComponent } from './components/customer-information/customer-information.component';
import { GenericComponent } from './components/generic/generic.component';
import { MiscItemsComponent } from './components/misc-items/misc-items.component';
import { MortgagesComponent } from './components/mortgages/mortgages.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { ReportComponent } from './components/report/report.component';
import { SalesCommissionComponent } from './components/sales-commission/sales-commission.component';
import { TaxesComponent } from './components/taxes/taxes.component';
import { TransactionInformationComponent } from './components/transaction-information/transaction-information.component';
import { TransactionLocationInformationComponent } from './components/transaction-location-information/transaction-location-information.component';
import { TransactionMiscInformationComponent } from './components/transaction-misc-information/transaction-misc-information.component';
import { TransactionSliderInformationComponent } from './components/transaction-slider-information/transaction-slider-information.component';
import { UserInformationComponent } from './components/user-information/user-information.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';

import { ApiConfig } from './services/api.service';
import { NetsheetConfigService } from './services/netsheet-config.service';
import { RateAmountDirective } from './directives/rate-amount.directive';

@NgModule({
  declarations: [
    NetsheetCalculatorComponent,
    CustomerInformationComponent,
    GenericComponent,
    MiscItemsComponent,
    MortgagesComponent,
    QuestionnaireComponent,
    ReportComponent,
    SalesCommissionComponent,
    TaxesComponent,
    TransactionInformationComponent,
    TransactionLocationInformationComponent,
    TransactionMiscInformationComponent,
    TransactionSliderInformationComponent,
    UserInformationComponent,
    ValidationErrorComponent,
    RateAmountDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'MM/DD/YYYY'
        },
        display: {
          dateInput: 'MM/DD/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'MM/DD/YYYY',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ],
  exports: [NetsheetCalculatorComponent]
})
export class NetsheetCalculatorModule {
  constructor(private netsheetConfigService: NetsheetConfigService) {}

  static forRoot(apiConfig: ApiConfig): ModuleWithProviders<NetsheetCalculatorModule> {
    return {
      ngModule: NetsheetCalculatorModule,
      providers: [{ provide: ApiConfig, useValue: apiConfig }]
    };
  }

  static forChild(): ModuleWithProviders<NetsheetCalculatorModule> {
    return {
      ngModule: NetsheetCalculatorModule
    };
  }
}
