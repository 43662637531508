import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  template: ''
})
export class GenericComponent implements OnDestroy {
  protected subscriptions: Subscription[] = [];
  protected uniqueSubscriptions: any = {};

  constructor() {}

  addUniqueSubscription(key: string, sub: any): void {
    this.cancelSubscription(key);
    this.uniqueSubscriptions[key] = sub;
  }

  cancelSubscription(key: string): void {
    if (key in this.uniqueSubscriptions) {
      this.uniqueSubscriptions[key].unsubscribe();
    }
  }

  cancelAllSubscriptions(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    for (const key in this.uniqueSubscriptions) {
      if (this.uniqueSubscriptions.hasOwnProperty(key)) {
        this.uniqueSubscriptions[key].unsubscribe();
      }
    }
  }

  ngOnDestroy(): void {
    this.cancelAllSubscriptions();
  }
}
