import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DeviceService } from './../../services/device.service';
import { QuestionnaireConfigService } from './../../services/questionnaire-config.service';
import { GenericComponent } from './../generic/generic.component';

import moment from 'moment';

@Component({
  selector: 'nsc-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent extends GenericComponent implements OnInit, OnChanges {
  @Input() form!: UntypedFormGroup;

  mainConfig: any = {};
  config: any = {
    homeownersDuesAmount: { visible: true },
    homeownersDuesDueDate: { visible: true },
    homeownersDuesPaid: { visible: true },
    homeownersDuesTerm: { visible: true },
    taxes: { visible: true },
    taxesNextInstallment: { visible: true },
    taxesPaid: { visible: true },
    taxesPaidToDateDropdown: { visible: true },
    taxesPaidToDatePicker: { visible: true },
    taxesTerm: { visible: true }
  };

  isMobile = false;
  hideComponent = false;

  availableTaxesPaidToDates: string[] = [];
  availableTaxesTerms: string[] = ['Quarterly', 'Semi-Annually', 'Annually'];
  availableHomeownersDuesTerms: string[] = ['Monthly', 'Quarterly', 'Semi-Annually', 'Annually'];

  get customerInformation(): AbstractControl | null {
    return this.form?.get('customerInformation');
  }

  get transactionInformation(): AbstractControl | null {
    return this.form?.get('transactionInformation');
  }

  get taxes(): AbstractControl | null {
    return this.form?.get('taxes');
  }

  constructor(private deviceService: DeviceService, private questionnaireConfigService: QuestionnaireConfigService) {
    super();
  }

  ngOnInit(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;

    this.subscriptions.push(
      this.deviceService.isMobile().subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
      })
    );

    this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.addUniqueSubscription(
        'property-state-change',
        this.transactionInformation?.get('propertyState')?.valueChanges.subscribe((state: string) => {
          this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
        })
      );

      this.addUniqueSubscription(
        'party-type-change',
        this.customerInformation?.get('partyType')?.valueChanges.subscribe(() => {
          this.updateAllCascade();
        })
      );

      this.addUniqueSubscription(
        'estcoe-change',
        this.transactionInformation?.get('attEstcoe')?.valueChanges.subscribe(() => {
          this.updateConfigByEstcoe();
          this.onConfigUpdate(false);
        })
      );
    }
  }

  getConfigLoadSubscription(state: string): Subscription {
    return this.questionnaireConfigService.getConfigByState(state).subscribe((response) => {
      this.mainConfig = response;

      this.onConfigUpdate(true);
      this.updateAllCascade();
    });
  }

  updateAllCascade(): void {
    this.updateConfigByPartyType();
    this.updateConfigByEstcoe();
    this.updateAvailableTaxesPaidToDatesByState();
    this.onConfigUpdate(false);
  }

  updateConfigByPartyType(): void {
    this.hideComponent = this.customerInformation?.get('partyType')?.value === 'L' ? true : false;
  }

  updateConfigByEstcoe(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;
    const estcoe = this.transactionInformation?.get('attEstcoe')?.value;

    if (estcoe) {
      const selectedMonth = moment(estcoe).get('M');

      if (state === 'OK') {
        if (selectedMonth === 10 || selectedMonth === 11) {
          this.config.taxesPaid = {
            visible: true,
            required: this.mainConfig.taxesPaid.required
          };
        } else {
          this.config.taxesPaid = {
            visible: false,
            required: false
          };
        }
      }

      if (state === 'TX') {
        if (selectedMonth === 0) {
          this.config.homeownersDuesPaid = {
            visible: true,
            required: this.mainConfig.homeownersDuesPaid.required
          };

          this.config.taxesPaid = {
            visible: false,
            required: false
          };
        } else if (selectedMonth === 11) {
          this.config.homeownersDuesPaid = {
            visible: false,
            required: false
          };

          this.config.taxesPaid = {
            visible: true,
            required: this.mainConfig.taxesPaid.required
          };
        } else {
          this.config.homeownersDuesPaid = {
            visible: false,
            required: false
          };

          this.config.taxesPaid = {
            visible: false,
            required: false
          };
        }
      }
    } else {
      if (state === 'OK') {
        this.config.taxesPaid = {
          visible: false,
          required: false
        };
      }

      if (state === 'TX') {
        this.config.homeownersDuesPaid = {
          visible: false,
          required: false
        };

        this.config.taxesPaid = {
          visible: false,
          required: false
        };
      }
    }
  }

  updateAvailableTaxesPaidToDatesByState(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;

    switch (state) {
      case 'CA':
        this.availableTaxesPaidToDates = [
          moment().set('M', 0).set('D', 1).format('MM/DD/YYYY'),
          moment().set('M', 6).set('D', 1).format('MM/DD/YYYY')
        ];

        break;
      case 'OH':
        const lastYear = new Date().getFullYear() - 1;

        this.availableTaxesPaidToDates = [
          moment().set('y', lastYear).set('M', 5).set('D', 30).format('MM/DD/YYYY'),
          moment().set('y', lastYear).set('M', 11).set('D', 31).format('MM/DD/YYYY')
        ];

        break;
      default:
        this.availableTaxesPaidToDates = [];
        break;
    }
  }

  onConfigUpdate(isMainConfigUpdated = false): void {
    for (const field of Object.keys(this.config)) {
      if (isMainConfigUpdated) {
        this.config[field] = this.mainConfig[field];
      }

      if (this.config[field].required) {
        this.taxes?.get(field)?.setValidators([Validators.required]);
      } else {
        this.taxes?.get(field)?.clearValidators();
      }
    }

    this.taxes?.updateValueAndValidity({ emitEvent: false });
  }
}
